{
  "name": "United Travel",
  "slug": "united-travel",
  "href": "/cases/united-travel",
  "preview": {
    "type": "video",
    "sources": [
      "/public/assets/cases/united-travel/hero-bg.webm",
      "/public/assets/cases/united-travel/hero-bg.mp4"
    ],
    "roles": "Product Design, Web Design, Branding",
    "label": "Case Study 01",
    "className": "hover-case"
  },
  "content": {
    "header": {
      "label": "United Travel LLC",
      "title": "Building an interactive ride booking tool & website",
      "dateRange": "September 2019 &ndash; August 2020",
      "projectUrl": "https://www.unitedtravelflorida.com/book"
    },
    "hero": {
      "type": "video",
      "sources": [
        "/public/assets/cases/united-travel/hero-bg.webm",
        "/public/assets/cases/united-travel/hero-bg.mp4"
      ]
    },
    "overview": {
      "role": {
        "label": "My Role",
        "title": "One Man Army",
        "description": "Product Design, Web Design, Full Stack Development, Copywriting"
      },
      "client": {
        "label": "Company",
        "title": "United Travel LLC",
        "description": "Worked on this project independantly through my consulting agency"
      }
    }
  },
  "sections": [
    {
      "component": "SideLabel",
      "props": {
        "sections": [
          {
            "label": "Overview",
            "content": [
              "I created an automated quote generator, product management system, and order management system. Linear LED is a company that specializes in custom LED installations. They create and install custom led fixtures for their clients. This makes having a good quotation and order system very vital. They already had a system; however, it was very limiting, and their business had outgrown it. In addition to that, it was plagued with several design shortcomings that made for a poor user experience."
            ],
            "cta": {
              "label": "Check out the website",
              "href": "https://www.linearled.ca",
              "target": "_blank"
            }
          }
        ]
      }
    },
    {
      "component": "FullScreenImage",
      "props": {
        "webp": "/public/assets/cases/united-travel/map.webp",
        "src": "/public/assets/cases/united-travel/map.png",
        "alt": "A lobby installation of the Linear LED product."
      }
    },
    {
      "component": "Section",
      "props": {
        "align": "center",
        "size": "large",
        "label": "The Problem",
        "heading": "Designing a website and product that conveys a sense of luxury and is intuitive and simple to use.",
        "content": [
          "When doing some market research on United Travel's competitors, I noticed that many of them did not have very intuitive booking systems. It was very common to see either confusing or bloated booking forms. The design language of many of the competitor sites was also extremely dated and inelegant.",
          "In addition to this, it was also important to craft a well designed marketing website. One of the primary goals that the company wanted to achieve with this website was to bring in more new clients."
        ],
        "cta": {
          "label": "Check out the website",
          "href": "https://www.linearled.ca",
          "target": "_blank"
        }
      }
    },
    {
      "component": "ColorSplashImage",
      "props": {
        "webp": "/public/assets/cases/united-travel/ride_type.webp",
        "src": "/public/assets/cases/united-travel/ride_type.jpg",
        "alt": "A lobby installation of the Linear LED product.",
        "color": "#333",
        "fullscreen": true
      }
    },
    {
      "component": "ContrastSection",
      "props": {
        "innerHTML": "<span>Automatic light &amp; dark themes.</span> The entire site switches between light and dark themes automatically based on the user's system theme.",
        "altBackground": false,
        "size": "medium",
        "align": "center"
      }
    },
    {
      "component": "Staggered",
      "props": {
        "altBackground": true,
        "halfPadding": true,
        "figures": [
          {
            "image": {
              "webp": "/public/assets/cases/united-travel/refer.webp",
              "src": "/public/assets/cases/united-travel/refer.jpg",
              "alt": "Some fucking alt text"
            },
            "caption": "Light Theme"
          },
          {
            "video": {
              "sources": [
                "/public/assets/cases/united-travel/hero-bg.webm",
                "/public/assets/cases/united-travel/hero-bg.mp4"
              ]
            },
            "caption": "Dark Theme"
          }
        ]
      }
    }
  ]
}
