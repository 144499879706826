import { createRoot } from "react-dom/client"
import Site from "./Site"

// Import Data
import unitedTravel from "../data/united-travel.json"
import linearLED from "../data/linear-led.json"

function updateDocumentHeight() {
  document.documentElement.style.setProperty(
    "--document-height",
    `${window.innerHeight}px`
  )
}

window.addEventListener("DOMContentLoaded", () => {
  // Update Document Height CSS variable
  window.addEventListener("resize", updateDocumentHeight)
  updateDocumentHeight()

  // Initialize React App
  const root = createRoot(document.querySelector("#site-root"))
  root.render(<Site projects={[unitedTravel, linearLED]} />)
})
