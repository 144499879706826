import { useCallback } from "react"

export const useBaseClassName = (baseClassName) => {
  return useCallback(
    (...classes) => {
      if (!classes || !classes.length) return baseClassName
      const joined = classes
        .map((className) => {
          return !className || typeof className !== "string"
            ? null
            : className.startsWith("_") || className.startsWith("-")
            ? baseClassName + className
            : className
        })
        .filter((className) => className)
        .join(" ")

      return joined || baseClassName
    },
    [baseClassName]
  )
}
