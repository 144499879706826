import { LinkButton } from "./Links"

export const Section = ({
  label,
  heading,
  content,
  cta,
  align = "center",
  size = "medium",
  altBackground = false
}) => {
  return (
    <section className={`section ${altBackground ? "alt" : ""}`.trim()}>
      <div className={`section__align ${align}`}>
        <div className={`section__content ${size}`}>
          {label && <h6 className="primary mb-60">{label}</h6>}
          {heading && <h3 className="mb-40">{heading}</h3>}
          {typeof content === "string" ? (
            <p>{content}</p>
          ) : (
            content.map((content, index) => <p key={index}>{content}</p>)
          )}
          {cta && (
            <div>
              <LinkButton {...cta} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export const SideLabelSection = ({ sections = [], altBackground = false }) => {
  return (
    <section className="section">
      {sections.map((section) => (
        <div className="section__grid" key={section.label}>
          <div className="section__label">
            <h6>{section.label}</h6>
          </div>

          <div className="section__content">
            {typeof section.content === "object" ? (
              section.content.map((content, index) => (
                <p key={index}>{content}</p>
              ))
            ) : (
              <p>{section.content}</p>
            )}
            {section.cta && (
              <div>
                <LinkButton {...section.cta} />
              </div>
            )}
          </div>
        </div>
      ))}
    </section>
  )
}

export const FullScreenImage = ({ webp, src, alt }) => {
  return (
    <section className="fullscreen__parallax">
      {webp ? (
        <picture>
          <source srcSet={webp} type="image/webp" />
          <source srcSet={src} type={`image/${src.split(".").pop()}`} />
          <img src={src} alt={alt} />
        </picture>
      ) : (
        <img src={src} alt={alt} />
      )}
    </section>
  )
}

export const FullScreenVideo = ({ sources }) => {
  return (
    <section className="fullscreen__parallax">
      <video className="bg-video__player" autoPlay muted loop playsInline>
        {sources.map((src) => (
          <source key={src} src={src} type={`video/${src.split(".").pop()}`} />
        ))}
      </video>
    </section>
  )
}

export const ColorSplashImage = ({ webp, src, alt, color, fullscreen }) => {
  return (
    <section className={`cs-banner ${fullscreen ? "fullscreen" : "tall"}`}>
      <div
        className="cs-banner__parallax"
        style={
          color
            ? {
                backgroundColor: color
              }
            : undefined
        }
      >
        <div className="cs-banner__screenshot">
          {webp ? (
            <picture>
              <source srcSet={webp} type="image/webp" />
              <source srcSet={src} type={`image/${src.split(".").pop()}`} />
              <img src={src} alt={alt} />
            </picture>
          ) : (
            <img src={src} alt={alt} />
          )}
        </div>
      </div>
    </section>
  )
}

export const ContrastSection = ({
  innerHTML,
  altBackground = false,
  size = "medium",
  align = "center"
}) => {
  return (
    <section className={`section ${altBackground ? "alt" : ""}`.trim()}>
      <div className={`section__align ${align}`}>
        <div className={`section__content ${size}`}>
          <h3
            className="span-contrast high"
            dangerouslySetInnerHTML={{
              __html: innerHTML
            }}
          />
        </div>
      </div>
    </section>
  )
}

export const StaggeredSection = ({ figures = [], altBackground }) => {
  return (
    <section
      className={`staggered section ${altBackground ? "alt" : ""}`.trim()}
    >
      <div className="staggered__grid">
        {figures.map((figure, index) => (
          <figure className="staggered__figure" key={index}>
            <div className="staggered__media">
              {figure?.video && (
                <video
                  className="staggered__video"
                  autoPlay
                  muted
                  loop
                  playsInline
                >
                  {figure?.video?.sources.map((src) => (
                    <source
                      key={src}
                      src={src}
                      type={`video/${src.split(".").pop()}`}
                    />
                  ))}
                </video>
              )}
              {!figure?.video && figure?.image && (
                <>
                  {figure?.image?.webp ? (
                    <picture>
                      <source srcSet={figure?.image?.webp} type="image/webp" />
                      <source
                        srcSet={figure?.image?.src}
                        type={`image/${figure?.image?.src.split(".").pop()}`}
                      />
                      <img src={figure?.image?.src} alt={figure?.image?.alt} />
                    </picture>
                  ) : (
                    <img src={figure?.image?.src} alt={figure?.image?.alt} />
                  )}
                </>
              )}
            </div>
            {figure?.caption && (
              <figcaption className="staggered__caption">
                <p>{figure?.caption}</p>
              </figcaption>
            )}
          </figure>
        ))}
      </div>
    </section>
  )
}
