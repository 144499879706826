import { useMatch } from "react-router"
const platforms = [
  {
    url: "https://dribbble.com/arjunsamir",
    icon: "dribbble"
  },
  {
    url: "https://www.linkedin.com/in/arjunsamir/",
    icon: "linkedin",
    modifier: "linkedin"
  },
  {
    url: "https://instagram.com/arjunsamir",
    icon: "instagram"
  }
]

export const NavLinks = ({ className, handleNavigation }) => {
  const matches = {
    work: useMatch("/work"),
    gallery: useMatch("/gallery")
  }

  return (
    <div className={className}>
      <a
        href="/work"
        className={matches.work ? "active" : null}
        onClick={handleNavigation}
      >
        Work
      </a>
      <a
        href="/gallery"
        className={matches.gallery ? "active" : null}
        onClick={handleNavigation}
      >
        Gallery
      </a>
      <a href="/resume">Resume</a>
      {/* <a href="#contact-target" data-relative-link="#contact-target">
        Contact
      </a> */}
    </div>
  )
}

export const SocialLinks = ({ className }) => {
  return (
    <div className={className}>
      {platforms.map(({ url, icon, modifier }) => (
        <a
          key={url}
          href={url}
          className={modifier ? `icon-btn ${modifier}` : "icon-btn"}
          target="_blank"
        >
          <svg>
            <use href={`/public/assets/icons/icons.svg#${icon}`} />
          </svg>
        </a>
      ))}
    </div>
  )
}

export const LinkButton = ({ href, target, className, label }) => {
  return (
    <a
      href={href}
      target={target}
      className={`link-btn ${className || ""}`.trim()}
    >
      {label}
    </a>
  )
}
