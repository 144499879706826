import { LinkButton, Footer, FullScreenImage } from "../components"

const Hero = () => {
  return (
    <>
      <header className="hero transition-into-view">
        <div className="hero__intro">
          <h1 className="span-primary mb-30">
            👋🏽 Hello, I'm <span>Arjun</span>
          </h1>
          <h4 className="text mb-20">
            I'm currently a front-end developer at Tesla with a background in
            UI/UX design and JavaScript development. I'm passionate about
            creating exceptional products & experiences.
          </h4>
          <h4 className="text span-contrast mb-60">
            <span className="heading-color">
              My portfolio is currently under construction with a refresh coming
              soon.
            </span>{" "}
            It's been almost five years since my last update, and a lot has
            changed. I've been busy exploring new opportunities and working on
            some exciting new projects that I can't wait to share with you. For
            now, you can view my 2019 portfolio below.
          </h4>
          <LinkButton
            href="https://archive.designdopethings.com"
            label="View 2019 Portfolio"
          />
        </div>
      </header>
    </>
  )
}

const Construction = () => {
  return (
    <main className="home united-travel">
      <Hero />
      <FullScreenImage
        src="/public/assets/images/arjun_shasta.jpg"
        alt="A picture of me standing in from of Mount Shasta"
      />
      <Footer />
    </main>
  )
}

export default Construction
