import { SocialLinks } from "./Links"
export const Footer = () => {
  return (
    <footer
      className="footer"
      data-scroll
      data-scroll-repeat
      data-scroll-class="transition-visible-item"
    >
      <div className="footer__container">
        <div className="footer__brand">
          <h3 className="mb-05">
            <a href="/">
              arjun samir <span>patel.</span>
            </a>
          </h3>
          <a href="mailto:hello@arjunsamir.com" className="default">
            hello@arjunsamir.com
          </a>
        </div>

        <SocialLinks className="footer__links" />
      </div>
    </footer>
  )
}
