import { ScrollIndicator } from "../components"
import { VideoPreview, ImagePreview, Footer } from "../components"
import { useNavigate } from "react-router-dom"
import { usePageTransition } from "../hooks"

const Work = (props) => {
  const { projects } = props
  const navigate = useNavigate()
  const { ref } = usePageTransition(props)
  return (
    <main className="work" ref={ref}>
      <header className="hero transition-into-view">
        <h6 className="h6 dim-50 mb-10">My Work</h6>

        <h2 className="span-primary lh-120">
          From <span>imagination</span> to execution.
        </h2>
        <p className="mb-100 mt-20 text">
          All projects have been fully designed and developed by me.
        </p>

        <ScrollIndicator className="mb-100" />
      </header>

      <section className="projects transition-into-view">
        <div className="projects__grid">
          {projects.map((project, index) =>
            project?.preview?.type === "video" ? (
              <VideoPreview
                key={index}
                className={project?.preview?.className}
                href={project?.href}
                sources={project?.preview?.sources}
                name={project?.name}
                roles={project?.preview?.roles}
                label={project?.preview?.label}
                onClick={(event) => {
                  event.preventDefault()
                  navigate(project?.href)
                }}
              />
            ) : (
              <ImagePreview
                key={index}
                className={project?.preview?.className}
                href={project?.href}
                webp={project?.preview?.webp}
                src={project?.preview?.src}
                alt={project?.preview?.alt}
                name={project?.name}
                roles={project?.preview?.roles}
                label={project?.preview?.label}
                onClick={(event) => {
                  event.preventDefault()
                  navigate(project?.href)
                }}
              />
            )
          )}
          {/* <VideoPreview
            className="hover-case"
            href="/cases/united-travel"
            sources={[
              "/public/assets/cases/united-travel/hero-bg.webm",
              "/public/assets/cases/united-travel/hero-bg.mp4"
            ]}
            name="United Travel"
            roles="Product Design, Web Design, Branding"
            label="Case Study 01"
          />
          <ImagePreview
            className="span-7 hover-case"
            href="/cases/linear-led"
            webp="/public/assets/cases/linear-led/cover-image.webp"
            src="/public/assets/cases/linear-led/cover-image.png"
            alt="A lobby installation of the Linear LED product."
            name="Linear LED"
            roles="Product Design, Development"
            label="Case Study 02"
          />
          <ImagePreview
            className="span-7 hover-coming-soon"
            href="/cases/recite-learning"
            webp="/public/assets/cases/recite-learning/cover-image.webp"
            src="/public/assets/cases/recite-learning/cover-image.png"
            alt="Another picture of me"
            name="Recite Learning"
            roles="Web Design, Web Development"
            label="Case Study 03"
          />
          <ImagePreview
            className="span-10 hover-coming-soon"
            href="/projects/df5"
            webp="/public/assets/projects/day-fiftyfive/cover.webp"
            src="/public/assets/projects/day-fiftyfive/cover.png"
            alt="A picture of me"
            name="Day Fiftyfive"
            roles="Web Design, Web Development"
            label="Project 01"
          />
          <ImagePreview
            className="span-9 hover-coming-soon"
            href="/projects/son-oilfield"
            webp="/public/assets/projects/son-oilfield/cover.webp"
            src="/public/assets/projects/son-oilfield/cover.png"
            alt="A website mockup"
            name="Son Oildfield"
            roles="Web Design, Web Development"
            label="Project 02"
          />
          <ImagePreview
            className="span-7 hover-coming-soon"
            href="/projects/you-matter-therapy"
            webp="/public/assets/projects/you-matter-therapy/cover.webp"
            src="/public/assets/projects/you-matter-therapy/cover.png"
            alt="A mobile website mockup"
            name="You Matter Therapy"
            roles="Web Design, Web Development"
            label="Project 03"
          /> */}
        </div>
      </section>
      <Footer />
    </main>
  )
}

export default Work
