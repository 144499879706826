import { useState } from "react"
import { Navbar } from "./components"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom"
import { ReactLenis } from "@studio-freight/react-lenis"

// Import pages
import Home from "./pages/Home"
import Work from "./pages/Work"
import Gallery from "./pages/Gallery"
import Construction from "./pages/Construction"

// Import templates
import CaseStudy from "./templates/CaseStudy"
import Project from "./templates/Project"

const SiteRoutes = ({ data, location }) => {
  return (
    <Routes location={location}>
      <Route exact path="/" element={<Home {...data} />} />
      <Route path="/work" element={<Work {...data} />} />
      <Route path="/gallery" element={<Gallery {...data} />} />
      <Route
        path="/cases/:name"
        element={<CaseStudy projects={data.projects} {...data} />}
      />
      <Route path="/projects/:name" element={<Project {...data} />} />
    </Routes>
  )
}

const Site = (props) => {
  const location = useLocation()
  const [displayLocation, setDisplayLocation] = useState(location)
  const [isAnimating, setIsAnimating] = useState(false)
  const [phase, setPhase] = useState("exit")

  const data = {
    ...props,
    location,
    displayLocation,
    setDisplayLocation,
    animation: { isAnimating, setIsAnimating, phase, setPhase }
  }

  return (
    <>
      <div className={isAnimating ? "site-previous" : "site-current"}>
        <SiteRoutes
          location={displayLocation}
          data={{ ...data, instance: "current" }}
        />
      </div>
      {isAnimating && (
        <div className="site-next">
          <SiteRoutes
            location={location}
            data={{ ...data, instance: "next" }}
          />
        </div>
      )}
    </>
  )
}

// Create Router
const SiteWrapper = ({ projects }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const data = { menuOpen, setMenuOpen, projects }

  return (
    <ReactLenis
      root
      options={{
        lerp: 0.15,
        wheelMultiplier: 2.5
      }}
    >
      <Router>
        <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <Site {...data} />
      </Router>
    </ReactLenis>
  )
}

const ConstructionPage = () => {
  return <Construction />
}

export default ConstructionPage
