import { ScrollIndicator, Footer } from "../components"
import { usePageTransition } from "../hooks"

const Hero = () => {
  return (
    <>
      <header className="hero transition-into-view">
        <div className="hero__intro">
          <h1 className="span-primary mb-30">
            👋🏽 Hello, I'm <span>Arjun</span>
          </h1>
          <h4 className="text">
            I'm currently a front-end developer at Tesla with a background in
            UI/UX design and JavaScript development. I'm passionate about
            creating exceptional products & experiences.
          </h4>
          <ScrollIndicator className="mt-60 mb-40" />
        </div>
      </header>
      <div className="hero-video">
        <video autoPlay muted loop playsInline>
          <source
            src="/public/assets/cases/united-travel/hero-bg.webm"
            type="video/webm"
          />
          <source
            src="/public/assets/cases/united-travel/hero-bg.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </>
  )
}

const Home = (props) => {
  const { ref } = usePageTransition(props)

  return (
    <main className="home" ref={ref}>
      <Hero />
      <section className="section">
        <div className="section__content">
          <p className="h2 lh-160">
            but strip away all the keywords and technical jargon, what I do is
            quite simple...
          </p>
        </div>
      </section>
      <section>
        <h1>I design dope things</h1>
      </section>
      <Footer />
    </main>
  )
}

export default Home
