import { useParams } from "react-router-dom"

const Project = () => {
  const { name } = useParams()
  return (
    <div>
      <h1>Project: {name}</h1>
      <p>Some content here</p>
    </div>
  )
}

export default Project
