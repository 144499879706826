export const VideoPreview = ({
  href,
  sources,
  name,
  roles,
  label,
  className,
  onClick
}) => {
  return (
    <a
      href={href}
      className={`featured__item ${className ?? ""}`.trim()}
      onClick={onClick}
    >
      <div className="featured__display">
        <video className="bg-video__player" autoPlay muted loop playsInline>
          {sources.map((src) => (
            <source
              key={src}
              src={src}
              type={`video/${src.split(".").pop()}`}
            />
          ))}
        </video>
      </div>
      <div className="featured__description">
        <h6 className="h6">{label}</h6>
        <p className="text">
          {name} <span>&ndash; {roles}</span>
        </p>
      </div>
    </a>
  )
}

export const ImagePreview = ({
  className,
  href,
  webp,
  src,
  alt,
  name,
  roles,
  label,
  onClick
}) => {
  return (
    <a
      href={href}
      className={`featured__item ${className ?? ""}`.trim()}
      onClick={onClick}
    >
      <div className="featured__display">
        {webp ? (
          <picture>
            <source srcSet={webp} type="image/webp" />
            <source srcSet={src} type={`image/${src.split(".").pop()}`} />
            <img src={src} alt={alt} />
          </picture>
        ) : (
          <img src={src} alt={alt} />
        )}
      </div>

      <div className="featured__description">
        <h6 className="h6">{label}</h6>
        <p className="text">
          {name} <span>&ndash; {roles}</span>
        </p>
      </div>
    </a>
  )
}
