import { useEffect, useLayoutEffect, useRef } from "react"
import { useLenis } from "@studio-freight/react-lenis"
import anime from "animejs"

export const usePageTransition = ({
  animation,
  location,
  instance,
  setDisplayLocation
}) => {
  const ref = useRef()
  const mounted = useRef(false)
  const tl = useRef()
  const lenis = useLenis()

  const { phase, isAnimating, setIsAnimating, setPhase } = animation

  // CREAT ANIMATIONS
  useLayoutEffect(() => {
    if (instance === "next") {
      // Set the initial opacity to 0
      anime.set(ref.current, { opacity: 0 })

      ref.current?.querySelectorAll("video").forEach((video) => {
        video?.pause()
      })

      // Create a new timeline
      tl.current = anime.timeline({
        easing: "easeOutCubic",
        autoplay: false
      })

      // Add the animations to the timeline
      tl.current.add({
        targets: ref.current,
        opacity: [0, 1],
        duration: 500
      })

      tl.current.finished.then(() => {
        setPhase("exit")
        setIsAnimating(false)
        setDisplayLocation(location)
      })
    }
    if (instance === "current") {
      if (lenis) {
        lenis.start()
      }
    }
  }, [])

  // EXIT ANIMATION
  useLayoutEffect(() => {
    if (
      mounted.current &&
      instance === "current" &&
      isAnimating &&
      phase === "exit"
    ) {
      // Create a new timeline
      const tl = anime.timeline({
        easing: "easeOutCubic",
        autoplay: true
      })

      // Add the animations to the timeline
      tl.add({
        targets: ref.current,
        opacity: [1, 0],
        translateX: [0, -250],
        duration: 500
      })

      tl.finished.then(() => {
        lenis.scrollTo("top", {
          immediate: true,
          onComplete: () => {
            lenis.stop()
            setPhase("enter")
          }
        })
      })
    }
  }, [isAnimating, phase])

  // ENTER ANIMATION
  useLayoutEffect(() => {
    if (mounted.current && instance === "next" && phase === "enter") {
      tl.current.play()
    }
  }, [phase])

  // Trigger the transition
  useEffect(() => {
    if (mounted.current && instance === "current") {
      setIsAnimating(true)
      setPhase("exit")
    }
  }, [location.pathname])

  // SET MOUNTED TO TRUE
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      return
    }
  }, [])

  return { ref, mounted: mounted.current }
}
