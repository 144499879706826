import { SocialLinks, NavLinks } from "./Links"
import { useNavigate } from "react-router-dom"

import { useRef, useCallback, useLayoutEffect } from "react"
import { useBaseClassName } from "../hooks"
import anime from "animejs"

export const Navbar = ({ menuOpen, setMenuOpen }) => {
  // Use Hooks
  const navigate = useNavigate()
  const join = useBaseClassName("navbar")

  // Create Refs
  const mounted = useRef(false)
  const ref = useRef(null)
  const animation = useRef(null)

  // Hanle Menu Open
  const openMenu = useCallback(() => {
    // Destroy old animation
    if (animation.current) {
      animation.current.pause()
      animation.current = null
    }

    // Create A new timeline
    const tl = anime.timeline({
      easing: "easeOutCubic",
      autoplay: false
    })

    // Add the animations to the timeline
    tl.add({
      targets: ref.current.querySelector(`.${join("__menu")}`),
      opacity: [0, 1],
      backdropFilter: "blur(40px)",
      duration: 500
    })
      .add(
        {
          targets: ref.current.querySelectorAll(`.${join("__menu-links")} a`),
          opacity: [0, 1],
          duration: 250,
          translateX: [-40, 0],
          delay: (_, i) => 50 + 100 * i
        },
        250
      )
      .add(
        {
          targets: [
            ...ref.current.querySelectorAll(`.${join("__menu-social")} a`)
          ].reverse(),
          opacity: [0, 1],
          duration: 200,
          translateY: [40, 0],
          delay: (_, i) => 50 + 100 * i
        },
        250
      )

    // Set the animation to the ref
    animation.current = tl
    tl.play()
  }, [])

  // Handle Menu Close
  const closeMenu = useCallback(() => {
    if (animation.current) {
      animation.current.reverse()
      animation.current.play()
      // Wait for the animation to finish and then destroy it
      animation.current.finished.then(() => {
        animation.current = null
      })
    }
  }, [])

  // Toggle Menu
  const toggleMenu = useCallback(() => {
    setMenuOpen((prev) => !prev)
  }, [])

  // Handle Navigation Action
  const handleNavigation = (event) => {
    event.preventDefault()
    if (menuOpen) closeMenu()
    const target = event.currentTarget.getAttribute("href")
    navigate(target)
  }

  // handle Menu Toggle
  useLayoutEffect(() => {
    if (mounted.current) {
      if (menuOpen) openMenu()
      else closeMenu()
    } else {
      mounted.current = true
    }
  }, [menuOpen])

  return (
    <nav className={join("navbar", menuOpen && "open")} ref={ref}>
      {/* Branding */}
      <a className={join("__brand")} href="/" onClick={handleNavigation}>
        as<span>p.</span>
      </a>

      {/* Main Navbar Links */}
      <NavLinks
        className={join("__links")}
        handleNavigation={handleNavigation}
      />

      {/* Menu Icon */}
      <div className={join("__icon", menuOpen && "open")} onClick={toggleMenu}>
        <span className={join("__icon-line", "top")} />
        <span className={join("__icon-line", "bottom")} />
      </div>

      {/* Mobile Nav Menu */}
      <div className={join("__menu", menuOpen && "open")}>
        <NavLinks
          className={join("__menu-links")}
          handleNavigation={handleNavigation}
        />
        <SocialLinks className={join("__menu-social")} />
      </div>
    </nav>
  )
}
