{
  "name": "Linear LED",
  "slug": "linear-led",
  "href": "/cases/linear-led",
  "preview": {
    "type": "image",
    "webp": "/public/assets/cases/linear-led/cover-image.webp",
    "src": "/public/assets/cases/linear-led/cover-image.png",
    "alt": "A lobby installation of the Linear LED product.",
    "roles": "Product Design, Development",
    "label": "Case Study 02",
    "className": "span-7 hover-case"
  }
}
