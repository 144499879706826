export const ProjectHeader = ({ label, title, dateRange, projectUrl }) => {
  return (
    <section className="cs-hero transition-into-view">
      <div className="cs-hero__container">
        {label && <h6 className="cs-hero__title">{label}</h6>}
        <h2 className="cs-hero__headline">{title}</h2>
        {dateRange && (
          <h5
            className="cs-hero__dates"
            dangerouslySetInnerHTML={{
              __html: dateRange
            }}
          />
        )}
        {projectUrl && (
          <div className="cs-hero__link">
            <a href={projectUrl} target="_blank" className="link-btn">
              View Live Site
            </a>
          </div>
        )}
      </div>
    </section>
  )
}

export const ProjectOverview = ({ role, client }) => {
  return (
    <section className="cs-info">
      <div className="cs-info__container">
        <div>
          <h6>{role?.label}</h6>
          <p className="heading-color medium">{role?.title}</p>
          <p>{role?.description}</p>
        </div>

        <div>
          <h6>{client?.label}</h6>
          <p className="heading-color medium">{client?.title}</p>
          <p>{client?.description}</p>
        </div>
      </div>
    </section>
  )
}

export const ThemeVideoHeader = ({ sources }) => {
  return (
    <div data-scroll-section>
      <section className="fullscreen fs-hero transition-into-view">
        <div className="fullscreen__parallax">
          <div
            className="cs-banner__screenshot fs"
            data-scroll
            data-scroll-speed="-2"
          >
            <video className="bg-video__player" autoPlay muted loop playsInline>
              {sources.map((src) => (
                <source
                  key={src}
                  src={src}
                  type={`video/${src.split(".").pop()}`}
                />
              ))}
            </video>
          </div>
        </div>
      </section>
    </div>
  )
}
